export const GET_BRAND_LIST_REQUEST = "GET_BRAND_LIST_REQUEST";
export const GET_BRAND_LIST_SUCCESS = "GET_BRAND_LIST_SUCCESS";
export const GET_BRAND_LIST_FAILURE = "GET_BRAND_LIST_FAILURE";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const GET_POPULAR_PRODUCT_REQUEST = "GET_POPULAR_PRODUCT_REQUEST";
export const GET_POPULAR_PRODUCT_SUCCESS = "GET_POPULAR_PRODUCT_SUCCESS";
export const GET_POPULAR_PRODUCT_FAILURE = "GET_POPULAR_PRODUCT_FAILURE";

export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAILURE = "GET_PRODUCT_DETAIL_FAILURE";

export const CHECK_CART_REQUEST = "CHECK_CART_REQUEST";
export const CHECK_CART_SUCCESS = "CHECK_CART_SUCCESS";
export const CHECK_CART_FAILURE = "CHECK_CART_FAILURE";

export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE";

export const GET_CHECKOUT_ORDER_REQUEST = "GET_CHECKOUT_ORDER_REQUEST";
export const GET_CHECKOUT_ORDER_SUCCESS = "GET_CHECKOUT_ORDER_SUCCESS";
export const GET_CHECKOUT_ORDER_FAILURE = "GET_CHECKOUT_ORDER_FAILURE";

export const PROCEED_CHECK_OUT_REQUEST = "PROCEED_CHECK_OUT_REQUEST";
export const PROCEED_CHECK_OUT_SUCCESS = "PROCEED_CHECK_OUT_SUCCESS";
export const PROCEED_CHECK_OUT_FAILURE = "PROCEED_CHECK_OUT_FAILURE";

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

export const GET_ALL_PRODUCT_BY_BRAND_REQUEST =
  "GET_ALL_PRODUCT_BY_BRAND_REQUEST";
export const GET_ALL_PRODUCT_BY_BRAND_SUCCESS =
  "GET_ALL_PRODUCT_BY_BRAND_SUCCESS";
export const GET_ALL_PRODUCT_BY_BRAND_FAILURE =
  "GET_ALL_PRODUCT_BY_BRAND_FAILURE";

  export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
  export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
  export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

  export const GET_USER_ORDER_LIST_REQUEST = "GET_USER_ORDER_LIST_REQUEST";
  export const GET_USER_ORDER_LIST_SUCCESS = "GET_USER_ORDER_LIST_SUCCESS";
  export const GET_USER_ORDER_LIST_FAILURE = "GET_USER_ORDER_LIST_FAILURE"

  export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";
  export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
  export const UPDATE_USER_INFO_FAILURE = "UPDATE_USER_INFO_FAILURE";